<template>
  <div>
    <div v-loading = "loading">
      <el-form :model="ruform" :rules="rules" ref="ruform" label-width="150px" style="margin-bottom:100px">
        <el-card>
          <template slot="header">
            基本信息
          </template>
          <el-form-item label="选择拼团活动：" prop="ActivityFullId">
            <el-select :disabled="disable||Boolean($route.query.Id)" v-model="ruform.ActivityFullId" filterable  style="width:400px" popper-class="selectpopper"
              placeholder="请选择要开启抢购的多人拼团活动" @change="selchange">
              <el-option v-for="(v,i) in ActivityFulllist" :key="i" :value="v.Id" :label="v.Name"></el-option>
            </el-select>
          </el-form-item>
          <div v-if="ruform.ActivityFullId">
            <el-form-item label="活动时间：" class = "havestar">
              <span v-if="ruform.StartTime">
                {{ruform.StartTime}}至{{ruform.EndTime}}
              </span>
            </el-form-item>
            <el-form-item label="成团人数：" class = "havestar">
              <span v-if="String(ruform.GroupMemberNum)">
                {{ruform.GroupMemberNum}}人
              </span>
            </el-form-item>
            <el-form-item label="抢中人数：" class = "havestar" prop="SnapUpMemberCount">
              <el-input :disabled="disable" v-model="ruform.SnapUpMemberCount" style="width:200px"></el-input>
              <span style="margin-left:10px">人</span>
              <div class = "grayfont">组团成功后，可以抢中活动商品的人数；该人数需小于成团人数；商家只需要给抢中人数发货，未抢中人数系统自动退款，并发放未抢中奖励</div>
            </el-form-item>
            <el-form-item label="未抢中奖励：" prop="Type" :rules="rules.Type">
              <el-radio-group :disabled="disable" v-model="ruform.Type" style="width:80%" @change="changeradio">    
                <div class = "flexCol" style="margin-top:-20px">
                  <div v-for="(v,i) in ruform.ActivitySpellGroupSnapUpGiftList" :key="v.Type">
                    <div class = "flexRow" style="margin-top:5px" v-if="v.Type==1">
                      <div><el-radio :label="v.Type"><span></span></el-radio></div>
                      <el-form-item :prop="'ActivitySpellGroupSnapUpGiftList.'+i+'.Awrads'" :rules="rules.Awrads" label-width="0px" style="margin-top:15px">
                        <div class = "flexRow">
                          <span style="color:#606266;">余额赠送</span>
                          <el-input :disabled="ruform.Type!=v.Type || disable" style="margin:0px 10px;width:200px" v-model="v.Awrads"></el-input>
                          <div style="font-size:14px">元</div>
                        </div>
                        <div class = "grayfont">余额赠送金，系统直接发放至客户储值余额账户中</div>
                      </el-form-item>
                    </div>
                    <div class = "flexRow" style="margin-top:5px" v-if="v.Type==2">
                      <div><el-radio :label="v.Type"><span></span></el-radio></div>
                      <el-form-item :prop="'ActivitySpellGroupSnapUpGiftList.'+i+'.Awrads'" :rules="rules.Awrads2" label-width="0px" style="margin-top:15px">
                        <div class = "flexRow">
                          <span style="color:#606266;">现金</span>
                          <el-input :disabled="ruform.Type!=v.Type || disable" style="margin:0px 10px;width:200px" v-model="v.Awrads"></el-input>
                          <div style="font-size:14px">元</div>
                        </div>
                        <div class = "grayfont ">若配置赠送现金，现金奖励直接发放至客户可提现金额中，客户可在小程序商城中申请提现</div>
                      </el-form-item>
                    </div>
                    <div style="margin-top:5px" v-if="v.Type==3">
                      <div class = "flexRow">
                        <div><el-radio :label="v.Type"><span></span></el-radio></div>
                        <el-form-item label-width="0px" :prop="'ActivitySpellGroupSnapUpGiftList.'+i+'.ActivityCouponList'" :rules="rules.ActivityCouponList" style="margin-top:15px">
                          <div class = "flexRow">
                            <span style="color:#606266;">优惠券</span>
                            <el-button type="text" style="margin-left:10px" @click="selectCouponShow=true" v-if="ruform.Type==3&&!disable">选择优惠券</el-button>
                          </div>
                          <div class = "grayfont ">若优惠券剩余数量少于赠送数量，将不再发放。请关注优惠券剩余数量并及时补充</div>
                        </el-form-item>
                      </div>
                      <coupon-table :disable="disable" style="margin-top:5px" ref="couponTable" v-show="ruform.Type==3" :coupons="Coupons" @refresh="couponDataChange"></coupon-table>
                    </div>
                  </div>
                </div>
              </el-radio-group>
            </el-form-item>
          </div>
        </el-card>
        <el-card style="margin-top:10px">
          <template slot="header">
            其他设置
          </template>
          <el-form-item label="预约直播：">
            <el-checkbox v-model="ruform.IsOpenSubscribeLive" :label="true">完成视频号直播预约，中奖概率提高</el-checkbox>
            <div style="font-size:12px;color:#909399;line-height:2">1.选中后，买家开团或参团若预约视频号直播，组团成功后，提高该买家的中奖概率</div>
            <div style="font-size:12px;color:#909399;line-height:2">2.如果视频号有多场直播预告，买家将预约最近一场直播 </div>
            <div style="font-size:12px;color:#909399;line-height:2">3.如果视频号当前无可预约的直播间，该配置失效</div>
          </el-form-item>
        </el-card>
      </el-form>
      <el-card class="bottombox">
        <el-button @click = "cancel">取消</el-button>
        <el-button type="primary" v-if="!disable" @click="save('ruform')">保存</el-button>
      </el-card>


      <el-dialog title="选择优惠券" :visible.sync="selectCouponShow" width="1000px" class="dialog data-dialog">
        <select-coupon
          api="activitycouponpopupList"
          :selectedData="Coupons"
          :params="['KeyWord', 'UseType','','','IsUse']"
          @getSelectList="getSelectCouponList"
          :visible.sync="selectCouponShow"
          :isUse="true"
          v-if="selectCouponShow"
        ></select-coupon>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  activitySpellGroupList,
  activitySpellGroupactivitySpellGroupSnapUpSave,
  activitySpellGroupactivitySpellGroupSnapUpDetail
} from '@/api/TurnTomySelf'
import selectCoupon from '@/components/SelectMultipCoupon';
import couponTable from './components/couponTable/couponTable.vue';
export default {
  components: {
    selectCoupon,
    couponTable,
  },
  data () {
    var checkSnapUpMemberCount = (rule, value, callback) => {
      if(!value){
        callback(new Error('请设置抢中人数'));
      }else if(value < 1 || value >= this.ruform.GroupMemberNum || value != parseInt(value)){
        callback(new Error('抢中人数需小于成团人数，最小设置1人，整数数值'));
      }else{
        callback();
      }
    };
    var checkAwrads = (rule, value, callback) => {
      let two = /^\d+(\.\d{1,2})?$/
      if(this.ruform.Type==1){
        if(value < 0.01 || value > 9999 || !two.test(value)){
          callback(new Error('金额请设置在0.01~9999元之间，最多2位小数'));
        }else{
          callback()
        }
      }else{
        callback()
      }
    };
    var checkAwrads2 = (rule, value, callback) => {
      let two = /^\d+(\.\d{1,2})?$/
      if(this.ruform.Type==2){
        if(value < 0.01 || value > 100 || !two.test(value)){
          callback(new Error('金额请设置在0.01~100元之间，最多2位小数'));
        }else{
          callback()
        }
      }else{
        callback()
      }
    };
    var checkActivityCouponList = (rule, value, callback) => {
      let two = /^\d+(\.\d{1,2})?$/
      console.log(value,this.ruform.ActivitySpellGroupSnapUpGiftList.find((v)=>{
        return v.Type==3
      }).ActivityCouponList)
      if(this.ruform.Type==3){
        if(!value || !value.length){
          callback(new Error('请选择赠送的优惠券'));
        }else{
          callback()
        }
      }else{
        callback()
      }
    };
    return {
      loading:false,
      ActivityFulllist:[],
      ruform:{
        Id:0,
        IsOpen:true,
        ActivityFullId:null,
        StartTime:'',
        EndTime:'',
        GroupMemberNum:'',
        SnapUpMemberCount:'',
        Type:null,
        ActivitySpellGroupSnapUpGiftList:[
          {
            Id:0,
            Type:1,
            TypeValue:'',
            Awrads:'',
            ActivityCouponList:[],
          },
          {
            Id:0,
            Type:2,
            TypeValue:'',
            Awrads:'',
            ActivityCouponList:[],
          },
          {
            Id:0,
            Type:3,
            TypeValue:'',
            Awrads:'',
            ActivityCouponList:[],
          },
        ],
        IsOpenSubscribeLive:false,
      },
      rules:{
        ActivityFullId:[
          { required: true, message: '请选择拼团活动', trigger: 'blur' },
        ],
        SnapUpMemberCount:[
          { validator: checkSnapUpMemberCount, trigger: 'blur' }
        ],
        Awrads:[
          { validator: checkAwrads, trigger: 'blur' }
        ],
        Awrads2:[
          { validator: checkAwrads2, trigger: 'blur' }
        ],
        ActivityCouponList:[
          { validator: checkActivityCouponList, trigger: 'blur' }
        ],
        Type:[
          // { validator: checkType, trigger: 'blur' },
          { required: true, message: '请至少选择1种奖励', trigger: 'blur' },
        ],
      },
      selectCouponShow:false,
      Coupons:[],
      disable:false,
    }
  },
  created () {
    this.geyactivelist()
    this.disable = Boolean(this.$route.query.lookover||this.$route.query.state==2)
  },
  methods: {
    changeradio(){
      this.$refs.ruform.clearValidate(['ActivitySpellGroupSnapUpGiftList.0.Awrads','ActivitySpellGroupSnapUpGiftList.1.Awrads','ActivitySpellGroupSnapUpGiftList.2.ActivityCouponList'])
    },
    cancel(){
      if(this.$route.query.sucpage){
        this.$router.push({
          path:'/market/spellgroupbuy'
        })
      }else{
        this.$router.go(-1)
      }
    },
    selchange(e){
      let msg = this.ActivityFulllist.find((v)=>{
        return v.Id==e
      })
      // console.log(msg)
      this.ruform.StartTime = msg.StartTime
      this.ruform.EndTime = msg.EndTime
      this.ruform.GroupMemberNum = msg.GroupMemberNum
    },
    getSelectCouponList(list){
      // console.log(this.Coupons,list)
      if(this.Coupons&&this.Coupons.length){
        let newlist = []

        list.map((v)=>{
          let data = this.Coupons.find((x)=>{
            return x.Id==v.Id
          })
          // console.log(data.SendActivityCouponCount,666)
          if(data){
            newlist.push(data)
          }else{
            v.SendActivityCouponCount = 1
            newlist.push(v)
          }
        })
        this.Coupons = newlist
      }else{
        this.Coupons = list.map((v)=>{
          v.SendActivityCouponCount = 1
          return v
        })
      }
      this.ruform.ActivitySpellGroupSnapUpGiftList.map((v)=>{
        if(v.Type==3){
          v.ActivityCouponList = this.Coupons
        }
        return v
      })
      this.selectCouponShow = false
    },
    couponDataChange(list){
      this.Coupons = list.map((v)=>{
        if(v.SendActivityCouponCount){
          v.SendActivityCouponCount = Number(v.SendActivityCouponCount)
        }else{
          v.SendActivityCouponCount = ''
        }
        return v
      })
      // console.log(this.Coupons[0].SendActivityCouponCount)
      this.ruform.ActivitySpellGroupSnapUpGiftList.map((v)=>{
        if(v.Type==3){
          v.ActivityCouponList = this.Coupons
        }
        return v
      })
    },
    save(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.$refs['couponTable'][0].validForm().then(()=>{
            this.tosave()
          }).catch(res=>{
            this.$message.error('请完善抢购规则配置')
            return false
          })
        } else {
          this.$message.error('请完善抢购规则配置')
          return false;
        }
      });
    },
    async tosave(){
      this.loading = true
      try{
        let data = JSON.parse(JSON.stringify(this.ruform))
        data.ActivitySpellGroupSnapUpGiftList = data.ActivitySpellGroupSnapUpGiftList.filter((v)=>{
          return v.Type == data.Type
        })
        data.Id = data.ActivityFullId
        if(this.$route.query.Id){
          data.IsOpen = false
        }
        data.noError = true
        // console.log(data)
        let res = await activitySpellGroupactivitySpellGroupSnapUpSave(data)
        // res.ErrorCode = 20000020
        // res.IsSuccess = false
        if(res.IsSuccess){
          this.$message.success('保存成功')
          this.cancel()
        }else if(res.ErrorCode==20000020){
          this.$confirm('保存失败。商城未绑定视频号ID，无法开启预约直播配置。', '提示', {
            confirmButtonText: '去绑定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$router.push({
              name:'liveCutIn',
              params:{
                tab:2
              }
            })
          }).catch(() => {});
        }else{
          this.$message.error(res.Message)
        }
      }finally{
        this.loading = false
      }
    },
    async geyactivelist(){
      this.loading = true
      try{
        let data = {
          Keywords:'',
          ActivityState:null,
          IsOpenSpellGroupSnapUp:true,
          Skip:0,
          Take:99999
        }
        if(this.$route.query.openId || !Number(this.$route.query.Id) ){
          data.IsOpenSpellGroupSnapUp = false
        }
        // console.log(data.IsOpenSpellGroupSnapUp)
        let result = await activitySpellGroupList(data)
        this.ActivityFulllist = result.Result.Results.filter((v)=>{
          if(this.$route.query.state==2){
            return v
          }else{
            return v.ActivityStateShow != '已结束'
          }
        })
        if(this.$route.query.openId){
          let ids = this.ActivityFulllist.map(v=>v.Id)
          if(!ids.includes(Number(this.$route.query.openId))){
            this.ruform.ActivityFullId = null
            this.$message.error('已结束的活动不支持开启抢购，请选择其他活动')
          }else{
            this.ruform.ActivityFullId = Number(this.$route.query.openId)
            this.selchange(this.ruform.ActivityFullId)
          }
        }
        if(this.$route.query.Id){
          let msg = {
            Id:this.$route.query.Id
          }
          let res2 = await activitySpellGroupactivitySpellGroupSnapUpDetail(msg)
          if(res2.IsSuccess){
            console.log(res2)
            let ruform = res2.Result
            this.ruform.Id = Number(ruform.Id)
            this.ruform.ActivityFullId = Number(ruform.Id)
            this.ruform.IsOpen = ruform.IsOpen
            this.ruform.StartTime = ruform.StartTime
            this.ruform.EndTime = ruform.EndTime
            this.ruform.ActivityFullId = ruform.ActivityFullId
            this.ruform.GroupMemberNum = ruform.GroupMemberNum
            this.ruform.SnapUpMemberCount = ruform.SnapUpMemberCount
            this.ruform.Type = ruform.ActivitySpellGroupSnapUpGiftList[0].Type
            this.ruform.ActivitySpellGroupSnapUpGiftList = this.ruform.ActivitySpellGroupSnapUpGiftList.map((v)=>{
              if(v.Type == ruform.ActivitySpellGroupSnapUpGiftList[0].Type){
                return ruform.ActivitySpellGroupSnapUpGiftList[0]
              }else{
                return v
              }
            })
            this.ruform.IsOpenSubscribeLive = ruform.IsOpenSubscribeLive
            this.Coupons = ruform.ActivitySpellGroupSnapUpGiftList[0].ActivityCouponList
          }
        }
        
      }finally{
        this.loading = false
      }
    },
  }
}
</script>

<style lang = "less" scoped>
.grayfont{
  font-size: 12px;
  color: #999999;
  line-height:1.5
}
.flexCol{
  display: flex;
  flex-direction: column;
}
.flexRow{
  display: flex;
  align-items: center;
}
.formgary{
  margin-bottom:24px
}
.havestar ::v-deep .el-form-item__label:before{
  content: '*';
  color: #F56C6C;
  margin-right: 4px;
}
.bottombox{
  position: fixed;
  bottom: 0px;
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: -5%;
  z-index: 999;
}
</style>
<style>
  .selectpopper{
    max-width: 400px;
  }
</style>