<template>
	<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px">
		<div style="margin-left: 22px;margin-top: 15px;">
			<el-table border :data="ruleForm.list" style="width: 100%" :row-key="rowKeys">
				<el-table-column label="优惠券" width="200px">
					<template slot-scope="scope">
						<div class="dialog-name-content">
							<div class="right">
								<div class="name">{{scope.row.CouponName}}</div>
								<span>{{scope.row.IsFailure?'已失效':scope.row.LeaveCount< 1? '数量=0': ''}}</span>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="UseTypeValue" label="类型"></el-table-column>
				<el-table-column prop="CouponContent" label="优惠内容"></el-table-column>
				<el-table-column prop="ActivityRangeTypeValue" label="适用商品"></el-table-column>
				<el-table-column label="赠送数量/人" width="200px">
					<template slot-scope="scope">
						<el-form-item :id="'row-'+scope.$index" label="" :prop="'list.' + scope.$index + '.SendActivityCouponCount'" :rules="rules.numRule" style="height:60px">
							<el-input :disabled="disable" v-model="scope.row.SendActivityCouponCount" @change="(val)=>numChange(val, scope.row, scope.$index)" 
									style="width: 150px;"></el-input>
						</el-form-item>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button :disabled="disable" style="color:#F56C6C;" @click="deleteSortCoupon(scope.row,scope.$index)" type="text">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="page" style="overflow: hidden;">
				<el-pagination v-if="total" style="margin-top:10px;margin-right:50px;float:right;" @size-change="handleCouponsSizeChange"
				 @current-change="handleCouponsCurrentChange" :current-page="current" :page-sizes="[5,10, 20]"
				 :page-size="size" layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
			
		</div>
	</el-form>
</template>

<script>
	
	export default {
		components: {
		},
		props:{
			coupons: {
				type: Array,
				default: ()=>{
					return []
				}
			},
			disable:{
				type:Boolean,
				default:false
			}
		},
		data() {
			
			var check = (rule, value, callback) => {
				// console.log(value< 1 ||value > 99 || value != parseInt(value), value)
				if (value< 1 ||value > 99 || value != parseInt(value)) {
					callback(new Error('数量请设置在1-99之间，整数'));
				}  else {
					callback();
				}
			};
			
			return {
				loading: false,
				current: 1,
				size: 5,
				
				list: [],
				
				ruleForm: {
					list: []
				},
				rules: {
					numRule: [{
						required: true,
						trigger: 'blur',
						validator: check
					}]
				}
			};
		},
		computed: {
			total(){
				return this.list.length
			},
		},
		watch:{
			coupons(){
				this.list = this.coupons;
				this.initPageData();
			}
		},
		beforeMount() {
			this.list = this.coupons;
			this.initPageData();
		},
		methods: {
			initPageData(){

				var list = JSON.parse(JSON.stringify(this.list));
				var array = list.splice((this.current-1)*this.size, this.size)

				if (this.current != 1 && !array.length){
					this.current = Math.ceil(list.length/this.size);
					array = list.splice((this.current-1)*this.size, this.size)
				}
				// console.log(this.coupons.map((v)=>{
				// 	return v.SendActivityCouponCount
				// }))
				this.ruleForm.list = array;
			},
			rowKeys(row) {
				return row.Id
			},
			handleCouponsSizeChange(val){
				this.size = val;
				this.initPageData();
			},
			handleCouponsCurrentChange(val){
				this.current = val;
				this.initPageData();
			},
			deleteSortCoupon(record, index){
				var list = JSON.parse(JSON.stringify(this.list));
				list = list.filter(item=>{
					return item.Id != record.Id;
				})
				this.list = list;
				this.$emit('refresh', this.list);
				
				this.initPageData();
			},
			validForm() {
				return new Promise((resolve, reject)=>{
					
					var index = -1;
					var dt = null;
					for (var i=0; i<this.list.length; i++){
						let value = this.list[i].SendActivityCouponCount
						if (value< 1 ||value > 99 || value != parseInt(value)){
							index = i;
							dt = this.list[i]
							break;
						}
					}
					// console.log(dt)
					
					this.current = Math.ceil(((index+1)/this.size));
					this.initPageData();
					if(dt){
						reject()
					}
					this.$nextTick(()=>{
						this.$refs['ruleForm'].validate((valid) => {
							if (valid) {
								// console.log(valid,'suc')
								resolve('success');
							} else {
								// console.log('errr')
								reject('fail')
							}
						});
					})
					
				})
			},
			numChange(val, dt, index){
				
				this.list.map(item=>{
					if (item.Id == dt.Id){
						item.SendActivityCouponCount = val;
					}
					return item;
				})
				
				var record1 = this.ruleForm.list[index];
				record1.SendActivityCouponCount = val;
				this.ruleForm.list.splice(index, 1, record1);
				
				this.$emit('refresh', this.list)
			}
		}
	}
</script>

<style lang="less" scoped>
.dialog-name-content {
		display: flex;
		flex-direction: row;

		img {
			width: 60px;
			height: 60px;
			object-fit: contain;
			margin-right: 10px;
		}

		.name {
			width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		span {
			color: #E51C23;
			font-size: 12px;
		}
	}
</style>
